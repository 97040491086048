$icon-font-path: '../fonts/';
// bower:scss
@import "bower_components/bootstrap-sass/assets/stylesheets/_bootstrap.scss";
@import "bower_components/slick-carousel/slick/slick.scss";
@import "bower_components/font-awesome/scss/font-awesome.scss";
@import "bower_components/select2/src/scss/core.scss";
// endbower

@font-face {
  font-family: 'Conv_AvenirLTStd-Light';
  src: url('../fonts/AvenirLTStd-Light.eot');
  src: local('☺'), url('../fonts/AvenirLTStd-Light.woff') format('woff'), url('../fonts/AvenirLTStd-Light.ttf') format('truetype'), url('../fonts/AvenirLTStd-Light.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Conv_AvenirLTStd Black';
  src: url('../fonts/AvenirLTStd Black.eot');
  src: local('☺'), url('../fonts/AvenirLTStd Black.woff') format('woff'), url('../fonts/AvenirLTStd Black.ttf') format('truetype'), url('../fonts/AvenirLTStd Black.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Conv_AvenirLTStd-Heavy';
  src: url('../fonts/AvenirLTStd-Heavy.eot');
  src: local('☺'), url('../fonts/AvenirLTStd-Heavy.woff') format('woff'), url('../fonts/AvenirLTStd-Heavy.ttf') format('truetype'), url('../fonts/AvenirLTStd-Heavy.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Conv_AvenirLTStd-Medium';
  src: url('../fonts/AvenirLTStd-Medium.eot');
  src: local('☺'), url('../fonts/AvenirLTStd-Medium.woff') format('woff'), url('../fonts/AvenirLTStd-Medium.ttf') format('truetype'), url('../fonts/AvenirLTStd-Medium.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Conv_Avenir Heavy Oblique';
  src: url('../fonts/Avenir Heavy Oblique.eot');
  src: local('☺'), url('../fonts/Avenir Heavy Oblique.woff') format('woff'), url('../fonts/Avenir Heavy Oblique.ttf') format('truetype'), url('../fonts/Avenir Heavy Oblique.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Conv_Avenir Book';
  src: url('../fonts/Avenir Book.eot');
  src: local('☺'), url('../fonts/Avenir Book.woff') format('woff'), url('../fonts/Avenir Book.ttf') format('truetype'), url('../fonts/Avenir Book.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

/* Space out content a bit */
body {
  font-family: 'Conv_AvenirLTStd-Light' !important;
  padding: 0;
  margin: 0;
}

a{
  text-decoration: none;
  -webkit-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
  -webkit-transform: translateY(-2px);
  -moz-transform: translateY(-2px);
  -ms-transform: translateY(-2px);
  -o-transform: translateY(-2px);
  transform: translateY(-2px);
}

.fa-chevron-right:before {
    content: "\f054" !important;
}
.fa-chevron-left:before {
    content: "\f053" !important;
}

.pages{
  background: url(../images/bg.png);
  height: 100%;
}

.inner-page{
  background: #fff;
  padding-top: 10%;
  @media screen and (max-width: 1024px) {
    background: transparent;
    padding: 0;
  }
}

.clear{
  clear: both;
}

.artikel{
  background: #008942;
}
.trick{
  background: #d42707;
}
.quick{
  background: #2a9aff;
}
.fun{
  background: #ff890d;
}
.modal-open .modal{
  background: rgba(0, 0, 0, 0.85);
}
#modal-onload{
  .modal-dialog{
    @media screen and (max-width: 415px) {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100vh;
    }
  }
}
#modalvideo{
  text-align: center;
  padding: 0!important;
  &:before{
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle;
    margin-right: -4px;
  }
  .modal-dialog{
    display: inline-block;
    text-align: left;
    vertical-align: middle;
    @media screen and (max-width: 767px) {
      width: 330px;
    }
    @media screen and (min-width: 1200px) {
      width: 940px;
    }
    .modal-content{
      background: none;
      background: none;
      box-shadow: none;
      border: none;
      .modal-header{
        position: absolute;
          padding: 0;
          border: none;
          right: -20px;
          .close{
            opacity: 1;
            color: #fff;
          }
      }
      .modal-body{
        padding: 0;
      }
    }
  }

}

.infographics-bg{
  background: #fff;
  .share-column{
    padding-top: 175px !important;
  }
}

#wrapper{
  position: relative;
  .loadmore-wrappe{
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.56);
    z-index: 10;
    text-align: center;
    .loadmore{
      display: flex;
      align-items: center;
      justify-content: center;
      height: 946px;
    }
  }
  .overlay {
      height: 100%;
      width: 0;
      position: fixed;
      z-index: 99;
      top: 0;
      right: 0;
      background-color: rgb(0,0,0);
      background-color: rgba(0,0,0, 0.9);
      overflow-x: hidden;
      transition: 0.5s;
      .menu-block{
        height: 100%;
        width: 50%;
        position: absolute;
        right: 0;
        background: #fff;
        a {
            padding: 8px;
            text-decoration: none;
            font-size: 36px;
            color: #818181;
            display: block;
            transition: 0.3s;
        }
        .closebtn {
            position: absolute;
            top: 20px;
            left: 15px;
            font-size: 14px;
            font-family: 'Conv_AvenirLTStd-Medium';
        }
        .overlay-content {
            position: relative;
            top: 15%;
            width: 100%;
            text-align: center;
            a{
              font-family: 'Conv_AvenirLTStd Black';
              font-size: 17px;
              border-top: 1px solid #838383;
              padding: 20px 0;
              margin: 0 20px;
            }
            .active{
              color: #008942;
            }
        }
      }
  }


  .header-wrapper-home{
    position: fixed;
    background: #fff;
    width: 100%;
    height: 100px;
    z-index: 11;
    overflow: hidden;
    -webkit-transition: height 0.3s;
    -moz-transition: height 0.3s;
    transition: height 0.3s;
    box-shadow: 0 -5px 15px 1px #000;
    .logos{
      background: #fff;
      ul{
        padding: 0;
        margin: 0;
        li{
          list-style: none;
          float: left;
          a{
            display: block;
            img{
              -webkit-transition: all 0.5s;
              -moz-transition: all 0.5s;
              transition: all 0.5s;
              width: 250px;
              transform: scale(0.9);
              margin: 5px 0;
            }
          }
        }
      }
    }
  }
  .abs{
    position: absolute;
    left: 0;
    right: 0;
  }
  .header-wrapper{
    height: 150px;
    z-index: 11;
    overflow: hidden;
    @media screen and (max-width: 1024px) {
      height: auto;
      background: #fff;
      width: 100%;
      position: fixed;
      left: 0;
    }
    .logos{
      width: 150px;
      a{
        display: block;
        padding: 15px;
        margin: 10px 0;
        background: #fff;
      }
    }
    .menus-mobile{
      @media screen and (max-width: 1024px) {
        display: block !important;
      }
      .btn-menu{
        padding: 30px 0px;
      }
    }
  }
  .small{
    height: 80px;
    .logos{
      li{
        a{
          img{
            padding: 2px 0 !important;
            width: 200px !important;
            transform: scale(0.7) !important;
            margin: 0 !important;
          }
        }
      }
    }
    #myScrollspy{
      ul{
        padding: 20px 0;
        li{
          font-size: 14px;
        }
      }
    }
  }
  .footer{
    background: #fff;
    .footer-column-one{
      .logos{
        margin-top: 20px;
        ul{
          padding: 0;
          margin: 0;
          li{
            list-style: none;
            float: left;
            padding: 0;
            margin: 10px 0;
            a{
              display: block;
              img{
                width: 200px;
              }
            }
          }
        }
      }
    }
    .footer-column-two{
      padding-top: 20px;
      .widget-menus{
        position: relative;
        z-index: 9;
        .title-widget{
          color: #2b2b2b;
          font-family: 'Conv_AvenirLTStd-Heavy';
          margin-bottom: 15px;
          text-transform: uppercase;
        }
        .menu{
          ul{
            padding: 0;
            margin: 0;
            li{
              list-style: none;
              margin-bottom: 5px;
              a{
                color: #616161;
                display: block;
                text-decoration: none;
                &:hover{
                  color: #9b9b9b;
                }
              }
              .active{
                color: #9b9b9b;
              }
            }
          }
        }
      }
      .menu-inline{
        display: inline-block;
        margin-top: 20px;
        ul{
          padding: 0;
          margin: 0;
          li{
            float: left;
            list-style: none;
            margin-right: 10px;
            font-size: 12px;
            padding-right: 10px;
            border-right: 1px solid #9b9b9b;
            font-family: 'Conv_Avenir Book';
            line-height: 12px;
            &:last-child{
              border-right: none;
            }
            a{
              text-decoration: none;
              color: #9b9b9b;
              &:hover{
                color: #9b9b9b;
              }
            }
            .active{
              color: #616161;
            }
          }
        }
      }
    }
    .footer-column-three{
      margin: 3px 0 30px;
      font-size: 12px;
      color: #9b9b9b;
    }
  }

  .menus-wrapper{
    @media screen and (max-width: 1024px) {
      display: none;
    }
    position: fixed;
    top: 30%;
    left: 0;
    z-index: 99;
    .menus{
      ul{
        padding-left: 0;
        margin-bottom: 0;
        li{
          list-style: none;
          font-family: 'Conv_AvenirLTStd-Medium';
          &:hover{
            font-family: 'Conv_AvenirLTStd Black';
            background: url(../images/menu-active.png) no-repeat left;
            a{
              color: #fff;
            }
          }
          a{
            color:#a1a1a1;
            text-decoration: none;
            display: block;
            padding: 8px 25px;
          }
        }
        .active{
          font-family: 'Conv_AvenirLTStd Black';
          a{
            background: url(../images/menu-active.png) no-repeat left;
            color: #fff;
          }
        }
      }
    }
  }

  #myScrollspy{
      ul{
        padding: 30px 0;
        margin: 0;
        text-align: center;
        li{
          list-style: none;
          font-size: 16px;
          display: inline-block;
          font-family: 'Conv_AvenirLTStd Black';
          text-transform: uppercase;
          @media screen and (min-width: 1024px)and(max-width: 1099px) {
            font-size: 13px;
          }
          a{
            color:#a1a1a1;
            text-decoration: none;
            display: block;
            background: transparent;
            &:hover{
              background-color:transparent;
              border-left: 2px solid #009147;
              color: #009147;
              border-radius: 0;
            }
            &:focus{
              background-color:transparent;
            }
          }
        }
        .active{
          font-family: 'Conv_AvenirLTStd Black';
          a{
            background: transparent;
            border-left: 2px solid #009147;
            color: #009147;
            border-radius: 0;
          }
        }
      }
  }

  .sosmedlang{
    text-align: right;
    .sosmed-floating{
      padding: 20px 0;
      display: inline-block;
      ul{
        padding: 0;
        margin: 0;
        li{
          list-style: none;
          font-size: 20px;
          float: left;
          margin-right: 20px;
          a{
            color: #d8d8d8;
          }
          .fb{
            &:hover{
              color: #3b5998;
            }
          }
          .twt{
            &:hover{
              color: #1dcaff;
            }
          }
        }
      }
    }
    .lang-floating{
      display: inline-block;
      padding: 30px 0;
      ul{
        padding: 0;
        margin: 0;
        li{
          list-style: none;
          float: left;
          margin-right: 20px;
          a{
            display: block;
          }
          &:nth-child(2){
            margin-right: 0;
          }
        }
      }
    }
  }

  .running-text{
    background: #000;
    color: #fff;
    position: fixed;
    bottom: 0;
    padding: 5px;
    width: 100%;
    z-index: 9;
  }

  .list-pohon-inner{
    padding-top: 8%;
  }

  .content-column-wrapper{
    @media screen and (max-width: 1024px) {
      padding-top: 100px;
    }
    .content-statis-wrapper{
      width: 600px;
      margin: 0 auto;
      @media screen and (max-width: 991px) {
        width: 100%;
      }
      .container{
        width: 100%;
        .title{
          font-family: 'Conv_AvenirLTStd Black';
          font-size: 40px;
          border-left: solid 5px #009147;
          padding-left: 20px;
          line-height: 40px;
          margin-bottom: 20px;
        }
        .column-contentx{
          // height: 500px;
          // width: 100% !important;
          // overflow: auto;
          padding-bottom: 50px;
          @media(max-width: 991px){
            height: auto;
          }
          // .jspContainer{
          //   .jspPane{
          //     padding-right: 20px !important;
          //   }
          //   .jspVerticalBar{
          //     width: 10px;
          //     background: none;
          //     .jspTrack{
          //       background: transparent url(../images/border.png) no-repeat center;
          //       .jspDrag{
          //         background: transparent url(../images/border-act.png) no-repeat center;
          //       }
          //     }
          //   }
          // }
        }
      }
    }
    .content-contact-wrapper{
      @media screen and (min-width: 1025px) {
        width: 600px;
        margin: 0 auto;
        overflow: hidden;
      }
      .column-top{
        @media screen and (min-width: 1200px) {
          margin-bottom: 50px;
          width: 600px;
        }
        margin-bottom: 20px;
        .logos{
          width: 30%;
          float: left;
        }
        .address{
          width: 60%;
          float: left;
          font-family: 'Conv_AvenirLTStd Black';
          font-size: 14px;
          border-left: solid 3.5px #009147;
          padding: 0 12px;
          margin-left: 10px;
          @media screen and (min-width: 568px)and(max-width: 991px) {
            font-size: 20px;
          }
          @media screen and (min-width: 1200px) {
            font-size: 24px;
            width: 60%;
          }
        }
      }
      .column-form{
        width: 100%;
        @media screen and (min-width: 1200px) {
          width: 600px;
        }
        .form-horizontal{
          .form-group{
            margin-bottom: 10px;
            @media screen and (max-width: 991px) {
              display: block;
              margin: 0 0 10px;
            }
            label{
              text-align: left;
              text-transform: uppercase;
              width: 33%;
              padding-left: 30px;
              float: left;
              @media screen and (max-width: 991px) {
                width: 100%;
                padding-left: 0;
              }
            }
            .side-label{
              width: 60%;
              float: left;
              @media screen and (max-width: 991px) {
                width: 100%;
                input{
                  width: 100%;
                }
              }
              @media screen and (min-width: 1200px) {
                width: 315px;
              }
            }
            input{
              border-radius:0;
            }
          }
        }
      }
    }
    .isix{
      background: #fff;
      padding-top: 5%;
    }
    .column-search-wrapper{
      margin-bottom: 10px;
      padding-left: 10%;
      overflow: hidden;
      @media screen and (max-width: 1024px) {
        text-align: center;
        padding: 0;
      }
      .container{
        .form-search{
          label{
            font-size: 20px;
            text-transform: uppercase;
            font-family: 'Conv_AvenirLTStd-Heavy';
          }
          input{
            border-bottom: 2px solid;
            border-top: none;
            border-left: none;
            border-right: none;
            outline: none;
            background: none;
            width: 40%;
            margin-left: 10px;
          }
          button{
            background: none;
            border: none;
            font-size: 20px;
          }
        }
      }
    }

    .filter-mobile{
      .btn-action-wrapper{
        text-align: center;
          .action{
            display: inline-block;
            margin: 20px 0 0;
            ul{
              display: flex;
              align-items: center;
              justify-content: left;
              margin: 0;
              padding: 0;
              li{
                list-style: none;
                float: left;
                text-transform: uppercase;
                letter-spacing: 1.1px;
                line-height: 1.9;
                a{
                  display: inline-block;
                  color: #fff;
                  text-decoration: none;
                  -webkit-border-top-left-radius: 50px;
                  -webkit-border-bottom-right-radius: 50px;
                  -moz-border-radius-topleft: 50px;
                  -moz-border-radius-bottomright: 50px;
                  border-top-left-radius: 50px;
                  border-bottom-right-radius: 50px;
                  padding: 5px 20px 5px 20px;
                  text-indent: 5px;
                  text-align: center;
                }
                .clear{
                  background: #929292;
                }
                .apply{
                  background: #009147;
                }
              }
            }
          }
        }
      .panel {
          padding: 0 30px;
          margin: 0;
          background: transparent;
          box-shadow: none;
          border: none;
          .panel-heading{
            background: #fff;
            .panel-title{
              font-size: 18px;
              font-family: 'Conv_AvenirLTStd-Heavy';
              text-transform: capitalize;
              span{
                margin-bottom: 5px;
                display: block;
                text-transform: uppercase;
                font-size: 10px;
                color: #414141;
                opacity: 0.5;
                font-family: 'Conv_AvenirLTStd-Light';
              }
            }
            a{
              display: block;
              text-decoration: none;
            }
          }
          .panel-collapse{
            background: #7fddad;
            text-transform: capitalize;
          }
          ul{
            padding: 0;
            margin: 0;
            li{
              text-transform: capitalize;
              list-style: none;
              font-size: 18px;
              margin: 10px 0;
              a{
                color: #fff;
                display: block;
                text-decoration: none;
              }
            }
          }
      }
    }
    .filter-search-wrapper{
      .column-wrapper{
        border-top: 1px solid #979797;
        border-bottom: 1px solid #979797;
        padding-left: 10%;
        @media screen and (max-width: 1024px) {
          padding-left: 0;
        }
        .column-block{
          float: left;
          width: 20%;
          height: 100%;
          border-right: 1px solid #979797;
          position: relative;
          margin-top: 10px;
          .select2{
            .select2-selection{
              border: none;
              outline: none;
            }
          }

          .txt{
            padding: 20px 20px 0;
            span{
              display: block;
              text-transform: uppercase;
              font-size: 10px;
              color: #414141;
              opacity: 0.5;
              font-family: 'Conv_AvenirLTStd-Light';
            }
          }
          .select2{
            // width: 100% !important;
            padding: 0 10px 20px;
            .select2-selection__rendered{
              font-size: 18px;
              text-align: left;
              font-family: 'Conv_AvenirLTStd-Heavy';
            }
          }
          &:nth-child(3){
            .txt{
              border: none;
            }
          }
        }
        .btn-action-wrapper{
          float: left;
          width: 38%;
          .action{
            ul{
              display: flex;
              align-items: center;
              justify-content: left;
              height: 92px;
              margin: 0;
              padding-top: 20px;
              li{
                list-style: none;
                float: left;
                text-transform: uppercase;
                letter-spacing: 1.1px;
                line-height: 1.9;
                a{
                  display: inline-block;
                  color: #fff;
                  text-decoration: none;
                  -webkit-border-top-left-radius: 50px;
                  -webkit-border-bottom-right-radius: 50px;
                  -moz-border-radius-topleft: 50px;
                  -moz-border-radius-bottomright: 50px;
                  border-top-left-radius: 50px;
                  border-bottom-right-radius: 50px;
                  padding: 5px 20px 5px 20px;
                  text-indent: 5px;
                  text-align: center;
                }
                .clear{
                  background: #929292;
                  &:hover{
                    background: #fff;
                    border: 1px solid #929292;
                    color: #929292;
                  }
                }
                .apply{
                  background: #009147;
                  &:hover{
                    background: #fff;
                    border: 1px solid #009147;
                    color: #009147;
                  }
                }
              }
            }
          }
        }
      }
    }

    .col-feature-wrapper{
      padding-bottom: 50px;
      .feature-wrapper{
        padding: 10px 20px;
        display: inline-block;
        @media screen and (max-width: 568px) {
          padding: 0;
        }
        .title{
          text-align: center;
          .title-col{
            white-space: nowrap;
            display: table;
            margin-left: auto;
            margin-right: auto;
            font-size: 25px;
            text-align: center;
            font-family: 'Conv_AvenirLTStd Black';
            text-transform: uppercase;
            padding-bottom: 0;
            border-bottom: 3px solid #008942;
            margin-bottom: 20px;
          }
        }
        .column{
          .column-block{
            @media screen and (max-width: 568px) {
              padding: 0;
            }
            .column-block-wrapper{
              position: relative;
              overflow: hidden;
              max-height: 300px;
              @media screen and (max-width: 991px) {
                margin-bottom: 20px;
                max-height: 250px;
              }
              @media screen and (min-width: 1900px) {
                max-height: 350px;
              }
              .img{
                -moz-transition: all 0.3s;
                -webkit-transition: all 0.3s;
                transition: all 0.3s;
                &:hover{
                   -moz-transform: scale(1.1);
                  -webkit-transform: scale(1.1);
                  transform: scale(1.1);
                }
                a{
                  display: block;
                  position: relative;
                  .ply{
                    position: absolute;
                    top: 20%;
                    text-align: center;
                    width: 100%;
                    font-size: 30px;
                    color: #fff;
                    .border{
                      width: 100%;
                      height: 100%;
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      i{
                        height: 70px;
                        border: 3px solid rgba(255, 255, 255, 0.73);
                        border-radius: 50%;
                        width: 70px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                      }
                    }
                  }
                }
                img{
                  height: 300px;
                  width: 100%;
                  object-fit:cover;
                  @media screen and (max-width: 991px) {
                    height: 450px;
                  }
                }
              }
              .desc{
                position: absolute;
                bottom: 1px;
                width: 100%;
                background: rgba(0,0,0,0.5);
                color: #fff;
                padding: 15px;
                font-size: 12px;
                line-height: 12px;
                height: 40%;
                text-transform: uppercase;
                font-family: 'Conv_AvenirLTStd Black';
                a{
                  color: #fff;
                  text-decoration: none;
                  font-size: 16px;
                  line-height: initial;
                  &:hover{
                    opacity: 0.5;
                  }
                }
                .category{
                  text-transform: uppercase;
                  margin-bottom: 7px;
                  position: relative;
                  a{
                    font-family: 'Conv_AvenirLTStd Black';
                    text-transform: capitalize;
                    display: inline-block;
                    color: #fff;
                    font-size: 13px;
                    padding: 4px 10px 2px 10px;
                    text-decoration: none;
                    text-transform: none;
                    margin-right: 5px;
                    position: absolute;
                    top: -20px;
                    font-weight: normal;
                  }
                  .artikel{
                    background: #008942;
                  }
                  .trick{
                    background: #d42707;
                  }
                  .quick{
                    background: #2a9aff;
                  }
                  .fun{
                    background: #ff890d;
                  }
                }
                .source{
                  position: absolute;
                  bottom: 10px;
                  font-size: 11px;
                  font-family: 'Conv_Avenir Heavy Oblique';
                }
              }
            }
          }          
        }
      }
    }

    .column-content-list-search{
      .container{
        padding: 20px 0;
        margin-left: 20%;
        width: 900px;
        @media screen and (max-width: 1024px) {
          padding: 0;
          width: auto;
          margin: 0;
        }
        .column-content-list-left{
          .column-content-list-left-wrapper{
            display: inline-block;
            width: 100%;
            @media screen and (max-width: 1024px) {
              padding: 0;
              border: none;
              background: transparent;
              box-shadow: none;
              width: 100%;
            }
            .column-content-list-left-block{
              margin: 20px 0;
              .row{
                position: relative;
              }
              .img{
                overflow: hidden;
                position: relative;
                a{
                  img{
                    -webkit-border-top-left-radius: 25px;
                    -moz-border-radius-topleft: 25px;
                    border-top-left-radius: 25px;
                    object-fit:cover;
                    min-height: 160px;
                    -moz-transition: all 0.3s;
                    -webkit-transition: all 0.3s;
                    transition: all 0.3s;
                    @media screen and (max-width: 991px) {
                      height: 300px;
                    }
                    &:hover{
                       -moz-transform: scale(1.1);
                      -webkit-transform: scale(1.1);
                      transform: scale(1.1);
                    }
                    @media screen and (max-width: 991px) {
                      width: 100%;
                    }
                  }
                  .ply{
                    position: absolute;
                    top: 0;
                    text-align: center;
                    height: 100%;
                    width: 100%;
                    font-size: 20px;
                    color: #fff;
                    .border{
                      width: 100%;
                      height: 100%;
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      i{
                        height: 50px;
                        border: 3px solid rgba(255, 255, 255, 0.73);
                        border-radius: 50%;
                        width: 50px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                      }
                    }
                  }
                }
              }
              .col-md-9{
                @media screen and (max-width: 991px) {
                  position: absolute;
                  bottom: 0;
                  left: 0;
                  right: 0;
                }
                .content{
                  position: relative;
                  min-height: 160px;
                  width: 63%;
                  @media screen and (max-width: 1024px) {
                    color: #fff;
                    padding: 10px;
                    // overflow: hidden;
                    width: 100%;
                    background: rgba(0, 0, 0, 0.5);
                  }
                  .category-column{
                    margin-bottom: 10px;
                    position: relative;
                    span{
                      font-family: 'Conv_AvenirLTStd Black';
                      text-transform: capitalize;
                      color: #fff;
                      text-transform: capitalize;
                      font-size: 12px;
                      width: 100px;
                      display: inline-block;
                      text-align: center;
                      padding: 4px 10px 2px 10px;
                      @media screen and (max-width: 1024px) {
                        font-size: 13px;
                        width: auto;
                        position: absolute;
                        top: -20px;
                      }
                    }
                  }
                  .title{
                    font-size: 16px;
                    line-height: normal;
                    font-family: 'Conv_AvenirLTStd-Heavy';
                    margin-bottom: 10px;
                    line-height: normal;
                    text-transform: uppercase;
                    a{
                      text-decoration: none;
                      color: #000;
                      letter-spacing: 1px;
                      @media screen and (max-width: 991px){
                        color: #fff;
                      }
                      &:hover{
                        color: #676767;
                      }
                    }
                  }
                  .source{
                    display: inline-block;
                    font-size: 11px;
                    text-transform: uppercase;
                    color: #676767;
                    text-transform: uppercase;
                    font-family: 'Conv_Avenir Heavy Oblique';
                    margin-left: 0;
                    letter-spacing: 1px;
                    @media screen and (max-width: 1024px) {
                      margin-left: 0;
                      font-size: 12px;
                      color: #fff;
                    }
                    
                  }
                  .desc{
                    font-size: 14px;
                    line-height: normal;
                    margin: 20px 0;
                    @media screen and (max-width: 991px) {
                      display: none;
                    }
                  }
                }
              }

            }
            .pagination-wrapper{
              text-align: center;
              nav{
                display: inline-block;
                margin-right: 20%;
                @media screen and (max-width: 1024px) {
                  margin: 0;
                }
                ul{
                  li{
                    font-family: 'Conv_AvenirLTStd-Heavy';
                    font-size: 12px;
                    @media screen and (max-width: 1024px) {
                      font-size: 16px;
                    }
                    a{
                      border: none;
                      color: #008942;
                      background: none;
                      cursor: pointer;
                    } 
                    .active{
                      color: #000;
                    }
                  }
                }
              }
            }
          }
        }
        .column-content-list-right{
          .feature-wrapper{
            padding: 20px;
            border: solid 0.3px rgba(0, 0, 0, 0.3);
            box-shadow: 0.5px 0.5px 1px 0 rgba(0, 0, 0, 0.5);
            .title{
              font-size: 25px;
              text-align: center;
              font-family: 'Conv_AvenirLTStd Black';
              text-transform: uppercase;
              padding-bottom: 10px;
              border-bottom: 3px solid #008942;
              margin-bottom: 20px;
            }
            .column-feature{
              font-size: 12px;
              .feature-block{
                margin-bottom: 20px;
                padding-bottom: 10px;
                border-bottom: solid 0.3px rgba(0, 0, 0, 0.3);
                .row{
                  a{
                    display: inline-block;
                    color: #434343;
                    text-decoration: none;
                    width: 100%;
                    position: relative;
                    .column-feature-left{
                        overflow: hidden;
                        max-height: 200px;
                        @media screen and (max-width: 1024px) {
                          max-height: 100%;
                        }
                      .ply{
                        position: absolute;
                        top: 0;
                        left: 0;
                        text-align: center;
                        height: 100%;
                        width: 100%;
                        font-size: 20px;
                        color: #fff;
                        .border{
                          width: 100%;
                          height: 100%;
                          display: flex;
                          align-items: center;
                          justify-content: center;
                          i{
                            height: 50px;
                            border: 3px solid rgba(255, 255, 255, 0.73);
                            border-radius: 50%;
                            width: 50px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                          }
                        }
                      }
                      .img{
                        -moz-transition: all 0.3s;
                        -webkit-transition: all 0.3s;
                        transition: all 0.3s;
                        &:hover{
                           -moz-transform: scale(1.1);
                          -webkit-transform: scale(1.1);
                          transform: scale(1.1);
                        }
                        img{
                          -webkit-border-top-left-radius: 25px;
                          -moz-border-radius-topleft: 25px;
                          border-top-left-radius: 25px;
                          object-fit: cover;
                          max-height: 330px;
                          width: 100%;
                          @media screen and (max-width: 1024px) {
                            border-radius: 0;
                          }
                        }
                      }
                    }
                    .column-feature-right{
                      @media screen and (min-width: 1205px) {
                        padding: 0;
                      }
                      .category-column{
                        margin: 5px 0;
                        .cat{
                          text-transform: capitalize;
                          font-family: 'Conv_AvenirLTStd Black';
                          color: #fff;
                          text-transform: capitalize;
                          padding: 2px;
                          font-size: 10px;
                          display: inline-block;
                          width: 60px;
                          text-align: center;
                        }
                        .source{
                          display: inline-block;
                        }
                      }
                    }
                  }
                }
              }
              .more{
                text-align: center;
                font-family: 'Conv_AvenirLTStd-Heavy';
                text-transform: uppercase;
                a{
                  display: inline-block;
                  text-decoration: none;
                  color: #676767;
                  &:hover{
                    color: #000;
                  }
                }
              }
            }
          }
        }
        .detail-column-feature-mobile{
          background: #fff;
          .feature-wrapper{
            padding: 20px;
            .title{
              font-size: 25px;
              text-align: center;
              font-family: 'Conv_AvenirLTStd Black';
              text-transform: uppercase;
              padding-bottom: 10px;
              border-bottom: 3px solid #008942;
              margin-bottom: 20px;
            }
            .column-feature{
              padding: 20px;
              border: solid 0.3px rgba(0, 0, 0, 0.3);
              box-shadow: 0.5px 0.5px 1px 0 rgba(0, 0, 0, 0.5);
              margin-bottom: 30px;
              .feature-block{
                a{
                  display: inline-block;
                  .column-feature-img{
                    position: relative;
                    .img{
                      img{
                        object-fit:cover;
                        width: 100%;
                      }
                    }
                    .ply{
                      position: absolute;
                      top: 0;
                      left: 0;
                      text-align: center;
                      height: 100%;
                      width: 100%;
                      font-size: 20px;
                      color: #fff;
                      .border{
                        width: 100%;
                        height: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        i{
                          height: 50px;
                          border: 3px solid rgba(255, 255, 255, 0.73);
                          border-radius: 50%;
                          width: 50px;
                          display: flex;
                          align-items: center;
                          justify-content: center;
                        }
                      }
                    }
                  }
                  .column-feature-desc{
                    .category-column{
                      margin: 10px 0;
                      span{
                        color: #fff;
                        text-transform: capitalize;
                        font-family: 'Conv_AvenirLTStd Black';
                        text-transform: capitalize;
                        padding: 2px 10px;
                        font-size: 12px;
                      }
                      img{
                        display: inline-block;
                        padding-left: 10px;
                      }
                    }
                    .title{
                      font-size: 14px;
                      text-align: left;
                      color: #000;
                      border: none;
                      margin-bottom: 0;
                    }
                    .date{
                      color: #494949;
                      font-size: 12px;
                    }
                  }
                }
              }
            }
          }
          .more{
            text-align: center;
            font-family: 'Conv_AvenirLTStd-Heavy';
            text-transform: uppercase;
            a{
              color: #000;
            }
          }
        }
      }

    }

    .detail-column-wrapper{
      font-size: 20px;
      border-bottom: 1px solid #979797;
      padding-bottom: 20px;

      .container{
        width: 900px;
        position: relative;
        @media screen and (max-width: 1024px) {
          width: 100%;
        }
        .detail-column-share-sosmed{
          width: 7%;
          position: absolute;
          left: -10%;
          @media screen and (max-width: 1024px) {
            display: none;
          }
          .share-side{
            .share-column{
              padding-top: 165px;
              .txt{
                color: #414141;
                text-transform: uppercase;
                font-family: 'Conv_AvenirLTStd Black';
                padding-top: 5px;
                border-bottom: 1px solid;
                padding-bottom: 5px;
                margin-bottom: 8px;
              }
              ul{
                padding: 0;
                margin: 0;
                text-align: center;
                li{
                  list-style: none;
                  line-height: 14px;
                  margin-bottom: 5px;
                  display: inline-block;
                  width: 100%;
                  a{
                    width: 30px;
                    height: 30px;
                    color: #fff;
                    border-radius: 50%;
                    font-size: 12px;
                    text-decoration: none;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    display: inline-block;
                    line-height: 31px;
                  }
                  .column{
                    float: left;
                    font-size: 12px;
                    text-transform: uppercase;
                    color: #414141;
                    margin-left: 5px;
                    span{
                      display: block;
                      font-family: 'Conv_AvenirLTStd-Heavy';
                      font-size: 14px;
                    }
                  }
                  .twt{
                    background: #2ba9e1;
                  }
                  .fb{
                    background: #3c5a99;
                  }
                }
              }
            }
          }
        }
        .full-width{
          width: 100%;
          position: relative;
          @media screen and (max-width: 1024px) {
            width: 100% !important;
          }
          #list-example{
            position: fixed;
            right: 0px;
            top: 50%;
            width: 200px;
            @media screen and (max-width: 991px) {
              display: none;
            }
            ul{
              li{
                text-align: right;
                line-height: normal;
                font-size: 10px;
                float: right;
                margin-bottom: 10px;
                padding-right: 20px;
                line-height: 20px;
                position: relative;
                &:before{
                  position: absolute;
                  right: 0;
                  top: 0;
                  content: '';
                  width: 0;
                  height: 100%;
                  background-color: rgba(31, 123, 62, 0.7);
                  -webkit-transition: width 1s;
                  -moz-transition: width 1s;
                  -o-transition: width 1s;
                  transition: width 1s;
                }
                a{
                  background: url(../images/daun-gede.png) no-repeat right;
                  border: none;
                  color: #fff;
                  text-decoration: none;
                  padding: 10px 30px 6px 10px;
                  span{
                    display: inline-block;
                  }
                }
                &:hover{
                  &:before{
                    width: 100%;
                  }
                  a{
                    color: #fff;
                    background: url(../images/daun-gede-act.png) no-repeat right;
                    span{

                    }
                  }
                }
              }
              .active{
                background: #1f7b3e;
                a{
                  color: #fff;
                  background: url(../images/daun-gede-act.png) no-repeat 95%;
                  span{

                  }
                }
              }
            }
          }
          .banner-top{
            position: relative;
            img{
              -webkit-border-top-left-radius: 100px;
              -webkit-border-bottom-right-radius: 100px;
              -moz-border-radius-topleft: 100px;
              -moz-border-radius-bottomright: 100px;
              border-top-left-radius: 100px;
              border-bottom-right-radius: 100px;
              object-fit:cover;
            }
            .shadow{
              position: absolute;
              background: rgba(0, 0, 0, 0.5);
              width: 100%;
              height: 100%;
              top: 0;
              -webkit-border-top-left-radius: 100px;
              -webkit-border-bottom-right-radius: 100px;
              -moz-border-radius-topleft: 100px;
              -moz-border-radius-bottomright: 100px;
              border-top-left-radius: 100px;
              border-bottom-right-radius: 100px;
            }
            .teks-banner{
              z-index: 1;
                position: absolute;
                bottom: 0px;
                color: #fff;
                font-size: 40px;
                border-left: 5px solid #fff;
                left: 30%;
                height: 50%;
                width: 50%;
                line-height: normal;
                font-family: 'Conv_AvenirLTStd-Heavy';
                padding-left: 20px;
                @media screen and (min-height: 420px)and(max-height: 736px) {
                  font-size: 20px;
                }
                @media screen and (min-height: 320px)and(max-height: 418px){
                  font-size: 40px;
                }
                @media screen and (min-width: 1200px)and(max-width: 1367px) {
                  font-size: 40px !important;
                }
            }
          }
          .share-mobile{
            @media screen and (max-width: 1024px) {
              .share-column{
                display: inline-block;
                width: 100%;
                .txt{
                  display: block;
                  float: left;
                }
                ul{
                  padding-left: 0px;
                  margin-top: 5px;
                  margin-left: 10px;
                  margin-bottom: 0;
                  li{
                    margin-right: 10px;
                  }
                }
              }
            }
          }
          .column-isi{
            margin-top: 20px;
            font-size: 20px;
            line-height: 1.6em;
            font-family: 'Conv_AvenirLTStd-Light' !important;
            @media screen and (max-width: 768px) {
              margin-top: 0;
            }
          }
          .infografik-isinya{
            p{
              padding: 0 10%;
            }
            h3{
             padding: 0 10%;
            }
            blockquote{
              font-size: 20px;
              border-left: 5px solid #009147;
              margin: 0 10%;
              h3{
                padding: 0;
                line-height: 30px;
              }
            }
            .img-large{
              padding: 0;
            }
          }
        }
        .detail-column-left{
          .img{
            margin-bottom: 20px;
            img{
              width: 100%;
            }
          }
          @media screen and (max-width: 1024px) {
            float: none;
            width: 100%;
          }
          .category-column{
            margin: 10px 0;
            span{
              color: #fff;
              text-transform: capitalize;
              font-family: 'Conv_AvenirLTStd Black';
              text-transform: capitalize;
              font-size: 12px;
              padding: 4px 10px 2px;
              display: inline-block;
            }
            img{
              display: inline-block;
              padding-left: 10px;
            }
          }
          .title{
              font-family: 'Conv_AvenirLTStd Black';
              color: #000;
              font-size: 40px;
              line-height: 40px;
              margin: 10px 0;
              @media screen and (max-width: 1024px) {
                font-size: 20px;
                line-height: 25px;
              }
          }             
          .date{
            margin: 20px 0;
            font-size: 12px;
            color: #494949;
          }
          .img-detail-slider{
            @media screen and (max-width: 767px) {
              margin-top: 80px;
            }
            .slider-paging-number{
              list-style: none;
              letter-spacing: 8px;
              padding-left: 0;
              li:nth-child(1n+2){
                display:none;
              }
            }
            .slick-prev{
              position: absolute;
              top: 35%;
              left: 0;
              z-index: 9;
              color: #fff;
              font-size: 20px;
              cursor: pointer;
              background: url(../images/angle-left.png) no-repeat left center;
              padding: 35px 25px 35px 10px;
            }
            .slick-next{
              position: absolute;
              top: 35%;
              right: 0;
              z-index: 9;
              color: #fff;
              font-size: 20px;
              cursor: pointer;
              background: url(../images/angle-right.png) no-repeat right center;
              padding: 35px 10px 35px 25px;
            }
            .img{
              img{
                -webkit-border-top-left-radius: 100px;
                -moz-border-radius-topleft: 100px;
                border-top-left-radius: 100px;
                object-fit: cover;
                max-height: 330px;
                width: 100%;
              }
            }
          }

          .content{
            img{
              margin: 20px 0;
              width: 100%;
              height: 400px;
              object-fit:cover;
            }
          }
          .share-column{
            padding-top: 20px;
            .txt{
              color: #414141;
              text-transform: uppercase;
              font-family: 'Conv_AvenirLTStd Black';
              float: left;
              padding-top: 5px;
            }
            ul{
              float: left;
              padding-left: 20px;
              @media screen and (max-width: 768px) {
                padding-left: 10px;
              }
              li{
                float: left;
                margin-right: 20px;
                list-style: none;
                @media screen and (max-width: 768px) {
                  margin-right: 10px;
                }
                a{
                  width: 30px;
                  height: 30px;
                  color: #fff;
                  border-radius: 50%;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  float: left;
                }
                .column{
                  float: left;
                  font-size: 12px;
                  text-transform: uppercase;
                  color: #414141;
                  margin-left: 5px;
                  line-height: 14px;
                  span{
                    display: block;
                    font-family: 'Conv_AvenirLTStd-Heavy';
                    font-size: 14px;
                  }
                }
                .twt{
                  background: #2ba9e1;
                }
                .fb{
                  background: #3c5a99;
                }
              }
            }
          }
        }
      }
      .large{
        @media screen and (min-width: 1900px) {
          width: 1030px !important;
        }
      }
      .detail-column-feature-mobile{
        background: #fff;
        .feature-wrapper{
          padding: 20px;
          .title{
            font-size: 25px;
            text-align: center;
            font-family: 'Conv_AvenirLTStd Black';
            text-transform: uppercase;
            padding-bottom: 10px;
            border-bottom: 3px solid #008942;
            margin-bottom: 20px;
          }
          .column-feature{
            padding: 20px;
            border: solid 0.3px rgba(0, 0, 0, 0.3);
            box-shadow: 0.5px 0.5px 1px 0 rgba(0, 0, 0, 0.5);
            margin-bottom: 30px;
            .feature-block{
              a{
                display: inline-block;
                .column-feature-img{
                  .img{
                    img{
                      object-fit:cover;
                      width: 100%;
                    }
                  }
                }
                .column-feature-desc{
                  .category-column{
                    margin: 10px 0;
                    span{
                      color: #fff;
                      text-transform: capitalize;
                      font-family: 'Conv_AvenirLTStd Black';
                      text-transform: capitalize;
                      padding: 2px 10px;
                      font-size: 12px;
                    }
                    img{
                      display: inline-block;
                      padding-left: 10px;
                    }
                  }
                  .title{
                    font-size: 14px;
                    text-align: left;
                    color: #000;
                    border: none;
                    margin-bottom: 0;
                  }
                  .date{
                    color: #494949;
                    font-size: 12px;
                  }
                }
              }
            }
          }
        }
        .more{
          text-align: center;
          font-family: 'Conv_AvenirLTStd-Heavy';
          text-transform: uppercase;
          a{
            color: #000;
          }
        }
      }
    }
  }

  .home-slide-wrapper{
    position: relative;
    .txt{
      color: #fff;
      font-family: 'Conv_AvenirLTStd Black';
      text-transform: uppercase;
      font-size: 52px;
      position: absolute;
      top: 40%;
      right: 5%;
      line-height: 52px;
      width: 30%;
      border-left: 5px solid #fff;
      padding-left: 20px;
      z-index: 9;
    }
    .slider-home-slider{
      position: relative;
      .img{
        position: relative;
        img{
          width: 100%;
          height: 630px;
          object-fit: cover;
          @media screen and (max-width: 991px) {
            object-fit: cover;
            height: 400px;
            width: 100%;
          }
          @media screen and (min-width: 1700px){
            max-height: 868px !important;
            object-fit: cover;
            height: 100%;
          }
        }
        .shadows{
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          background: rgba(0, 0, 0, 0.3);
        }
        .txt{
          color: #fff;
          font-family: 'Conv_AvenirLTStd Black';
          text-transform: uppercase;
          font-size: 52px;
          position: absolute;
          top: 40%;
          right: 5%;
          line-height: 52px;
          width: 30%;
          border-left: 5px solid #fff;
          padding-left: 20px;
          @media screen and (max-width: 991px) {
            position: absolute;
            line-height: normal;
            font-size: 22px;
            top: 60%;
            width: 70%;
          }
        }
      }

      .slick-dots{
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        text-align: center;
        padding: 0;
        li{
          text-indent: -9999px;
          display: inline-block;
          list-style: none;
          width: 14px;
          height: 14px;
          margin: 0 5px;
          cursor: pointer;
          background: url(../images/daun-non.png) no-repeat center;
        }
        .slick-active{
          background: url(../images/daun-act.png) no-repeat center;
        }
      }
    }
  }


  .section-home-video{
    background: url(../images/bg-video.jpg) no-repeat;
    min-height: 63vw;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    @media screen and (max-width: 1024px) {
      padding-top: 100px;
    }
    .btn-play{
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: 55vw;
      a{
        &:hover{
          opacity: 0.8;
        }
      }
    }
    .txt{
      color: #fff;
      font-family: 'Conv_AvenirLTStd Black';
      text-transform: uppercase;
      font-size: 52px;
      position: absolute;
      top: 40%;
      right: 5%;
      line-height: 52px;
      width: 30%;
      border-left: 5px solid #fff;
      padding-left: 20px;
      @media screen and (max-width: 991px) {
        position: initial;
        line-height: normal;
        font-size: 22px;
        margin: auto;
        width: 70%;
      }
    }
  }

  .section-home-two{
    padding-top: 10%;
    .container{
      width: 850px;
      @media screen and (max-width: 1024px) {
        width: 100%;
      }
      .title{
        font-size: 40px;
        line-height: 40px;
        font-family: 'Conv_AvenirLTStd-Heavy';
        width: 55%;
        margin-bottom: 4%;
        @media screen and (max-width: 991px) {
          font-size: 22px;
          line-height: initial;
          width: 100%;
        }
      }
      .column-left{
        color: #616161;
        float: left;
        width: 50%;
        margin-right: 20px;
        @media screen and (max-width: 991px) {
          width: 100%;
        }
        .see{
          background: url(../images/bg-see.png) no-repeat;
          background-size: contain;
          z-index: 1;
          position: relative;
          margin-top: 10%;
          min-height: 30px;
          width: 166px;
          @media screen and (max-width: 991px) {
            margin: 5% 0;
            background-position: center;
            text-align: center;
          }
          a{
            text-decoration: none;
            color: #fff;
            display: block;
            line-height: 33px;
            text-align: center;
            padding-top: 2px;
            @media screen and (max-width: 991px){
              padding-left: 0;
            }
          }
        }
      }
    }
    .column-img{
      position: relative;
      width: 100%;
      .img-mask-left{
        float: left;
        margin-top: -7%;
        margin-left: 13%;
        @media screen and (max-width: 1024px) {
          margin: 20px 0;
          width: 50%;
        }
        @media screen and (min-width: 1600px) {
          margin-left: 28%;
          margin-top: -5%;
        }
      }
      .img-mask-right{
        float: right;
        @media screen and (max-width: 1024px) {
          width: 40%;
          margin-top: 35px;
          margin-right: 30px;
        }
        @media screen and (min-width: 1600px)and(max-width: 1900px) {
          margin-right: 3%;
        }
        @media screen and (min-width: 1920px) {
          margin-right: 14%;
        }
      }
    }
    .inner-two{
      position: relative;
      padding-left: 12%;
      @media screen and (max-width: 991px) {
        padding-left: 0;
      }
      .title2{
        font-size: 40px;
        line-height: 40px;
        font-family: 'Conv_AvenirLTStd-Heavy';
        margin-bottom: 4%;
        @media screen and (max-width: 991px){
          font-size: 22px;
          margin-bottom: 0;
          line-height: inherit;
        }
      }
      .col-text{
        color: #616161;
        -webkit-column-count: 2; /* Chrome, Safari, Opera */
        -moz-column-count: 2; /* Firefox */
        column-count: 2;
        column-gap: 50px;
        text-align: left;
         @media screen and (max-width: 991px){
          width: 100%;
          column-count: 1;
          column-gap: normal;
         }
      }
    }
    .inner-three{
      margin-top: 3%;
      padding-bottom: 5%;
      .container{
        width: 860px;
        padding-left: 5%;
        padding-right: 0%;
        @media screen and (max-width: 1024px) {
          width: 100%;
          padding-left: 0;
        }
        @media screen and (min-width: 1600px) {
          width: 990px;
          padding-left: 10%;
        }
      }
      .column{
        text-align: center;
        min-height: 120px;
        .col-block{
          .number{
            text-align: center;
            font-size: 30px;
            color: #000;
            font-family: 'Conv_AvenirLTStd-Heavy';
            img{
              display: inline-block;
              height: 80px;
            }
            .number-right{
              display: inline-block;
              min-width: 200px;
            }
          }
        }
        .txt{
          padding: 0 15%;
        }
      }
    }
  }
  .section-home-three{
    padding-top: 80px;
    .program-kami-wrapper{
      .bg-program{
        position: relative;
        height: 50vw;
        @media screen and (max-height: 415px) {
          height: 100%;
        }
        @media screen and (min-height: 416px)and(max-height: 1024px) {
          height: 80vh;
        }
        @media screen and (min-width: 1200px)and(max-width: 1366px) {
          height: 100vh;
        }
        .slider-for{
          height: 100%;
          .slick-prev{
            position: absolute;
            top: 50%;
            left: 0;
            z-index: 9;
          }
          .slick-next{
            position: absolute;
            top: 50%;
            right: 0;
            z-index: 9;
          }
          .slick-list{
            height: 100%;
            .slick-slide{
              height: 100%;
              img{
                width: 100%;
                @media screen and (max-height: 415px) {
                  height: 120vh;
                }
                @media screen and (min-height: 416px)and(max-height: 1024px) {
                  object-fit: cover;
                  height: 100vh;
                }
              }
            }

          }

          .slide4{
            background: url(../images/home-slide4.jpg) no-repeat;
            background-size: cover;
          }
        }
        .shadow{
          position: absolute;
          background: rgba(0,0,0,0.5);
          width: 100%;
          height: 100%;
          top: 0;
        }
        .content-program{
          position: absolute;
          top: 0;
          left: 0;
          z-index: 1;
          height: 100%;
          width: 100%;
          color: #fff;
          text-align: center;
          .title{
            text-transform: uppercase;
            font-size: 30px;
            border-left: 3px solid #008942;
            line-height: 60px;
            padding-top: 10px;
            padding-left: 20px;
            display: inline-block;
            font-family: 'Conv_AvenirLTStd Black';
            margin-bottom: 10%;
          }
          .slider-nav{
            .slick-list{
              text-align: center;
              width: 100%;
              .slick-track{
                display: inline-block !important;
                @media screen and (min-width: 1200px) {
                  width: auto !important;
                  transform: translate3d(0px, 0px, 0px) !important;
                }
                @media screen and (max-width: 991px) {
                  display: block !important;
                  transform: none;
                }
                .slick-slide{
                  padding: 20px;
                  cursor: pointer;
                  text-transform: uppercase;
                  opacity: 0.5;
                  &:hover{
                    opacity: 1;
                  }
                  .menus{
                    text-align: center;
                    .img{
                      text-align: center;
                      margin-bottom: 5px;
                      @media screen and (max-width: 768px) {
                        display: inline-block;
                        margin-right: 10px;
                        width: 100%;
                      }
                      img{
                        display: inline-block;
                        @media screen and (max-width: 768px) {
                          width: 30px;
                        }
                      }
                    }
                    .txts{
                      @media screen and (max-width: 768px) {
                       display: inline-block;
                      }
                    }
                  }
                  @media screen and (max-width: 1024px) {
                    border: none;
                    font-size: 12px;
                  }
                  @media screen and (min-width: 1200px) {
                    width: auto !important;
                  }
                  @media screen and (max-width: 991px) {
                    display: inline-block;
                    text-align: center;
                  }
                }
                .slick-current{
                  opacity: 1;
                  font-weight: bolder;
                }
              }
            }
          }
          .content-isi-wrapper{
            margin-top: 4%;
            .slick-prev{
              position: absolute;
              left: 18%;
              font-size: 40px;
              z-index: 99;
              cursor: pointer;
              display: none;
              @media screen and (max-width: 991px) {
                left: 0%;
              }
            }
            .slick-next{
              position: absolute;
              right: 18%;
              font-size: 40px;
              z-index: 99;
              top: 0;
              cursor: pointer;
              @media screen and (max-width: 991px) {
                right: 0%;
              }
            }
            .content-isi{
              text-align: center;
              font-size: 22px;
              width: 50% !important;
              @media screen and (max-width: 991px) {
                font-size: 18px;
                width: 80% !important;
              }
              .detail{
                margin-top: 50px;
                font-family: 'Conv_AvenirLTStd-Medium';
                a{
                  line-height: 36px;
                  padding-left: 10px;
                  -webkit-border-top-left-radius: 100px;
                  -webkit-border-bottom-right-radius: 100px;
                  -moz-border-radius-topleft: 100px;
                  -moz-border-radius-bottomright: 100px;
                  border-top-left-radius: 100px;
                  border-bottom-right-radius: 100px;
                  border: 2px solid #fff;
                  width: 200px;
                  letter-spacing: 2px;
                  outline: none;
                  font-size: 14px;
                  color: #fff;
                  text-transform: uppercase;
                  height: 50px;
                  display: inline-block;
                  text-decoration: none;
                  @media screen and (max-width: 1366px) {
                    height: 40px;
                    line-height: 38px;
                  }
                  @media screen and (min-width: 1600px) {
                    line-height: 45px;
                  }
                  &:hover{
                    background: #fff;
                    color: #000;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .section-home-fourth{
    .cerita-pohon-wrapper{
      background: url(../images/bg.png);
      overflow: hidden;
      // padding: 80px 0 0;
      .title-wrapper{
        text-align: center;
        .title{
          text-transform: uppercase;
          color: #161616;
          font-size: 30px;
          line-height: 60px;
          padding-top: 10px;
          padding-left: 20px;
          display: inline-block;
          font-family: "Conv_AvenirLTStd Black";
          margin-bottom: 5%;
          border-left: 3px solid rgb(0, 137, 66);
        }
      }
      .container{
        @media screen and (min-width: 1600px) {
          width: 1350px;
        }
      }
      .loadmore{
        text-align: center;
        font-family: 'Conv_AvenirLTStd-Medium';
        @media screen and (max-width: 991px) {
          margin-bottom: 50px;
        }
        a{
          -webkit-border-top-left-radius: 100px;
          -webkit-border-bottom-right-radius: 100px;
          -moz-border-radius-topleft: 100px;
          -moz-border-radius-bottomright: 100px;
          border-top-left-radius: 100px;
          border-bottom-right-radius: 100px;
          border: 2px solid #000;
          width: 220px;
          font-size: 14px;
          color: #000;
          text-transform: uppercase;
          height: 50px;
          line-height: 36px;
          padding-left: 10px;
          display: inline-block;
          text-decoration: none;
          font-weight: bolder;
          text-align: center;
          letter-spacing: 2px;
          @media screen and (max-width: 1366px) {
            height: 40px;
            line-height: 38px;
          }
          @media screen and (min-width: 1600px) {
            line-height: 50px;
          }
          &:hover{
            background: #000;
            color: #fff;
          }
        }
      }
      .column{
        margin-bottom: 20px;
        @media screen and (max-width: 991px) {
          margin-bottom: 0;
        }
        .column-block{
           @media screen and (min-width: 1200px) {
             padding: 0 10px;
           }
          .column-block-wrapper{
            position: relative;
            overflow: hidden;
            max-height: 300px;
            @media screen and (max-width: 991px) {
              margin-bottom: 20px;
            }
            @media screen and (min-width: 1900px) {
              max-height: 350px;
            }
            .img{
              -moz-transition: all 0.3s;
              -webkit-transition: all 0.3s;
              transition: all 0.3s;
              &:hover{
                 -moz-transform: scale(1.1);
                -webkit-transform: scale(1.1);
                transform: scale(1.1);
              }
              a{
                display: block;
                position: relative;
                .ply{
                  position: absolute;
                  top: 20%;
                  text-align: center;
                  width: 100%;
                  font-size: 30px;
                  color: #fff;
                  .border{
                    width: 100%;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    i{
                      height: 70px;
                      border: 3px solid rgba(255, 255, 255, 0.73);
                      border-radius: 50%;
                      width: 70px;
                      display: flex;
                      align-items: center;
                      justify-content: center;
                    }
                  }
                }
              }
              img{
                height: 350px;
                width: 100%;
                object-fit:cover;
                @media screen and (max-width: 991px) {
                  height: 450px;
                }
              }
            }
            .desc{
              position: absolute;
              bottom: 1px;
              width: 100%;
              background: rgba(0,0,0,0.5);
              color: #fff;
              padding: 20px 15px;
              font-size: 16px;
              line-height: 16px;
              height: 40%;
              text-transform: uppercase;
              font-family: 'Conv_AvenirLTStd Black';
              a{
                line-height: 1.4em;
                letter-spacing: 1px;
                color: #fff;
                text-decoration: none;
                &:hover{
                  opacity: 0.5;
                }
              }
              .category{
                text-transform: uppercase;
                margin-bottom: 7px;
                position: relative;
                a{
                  font-size: 14px;
                  text-transform: capitalize;
                  font-family: 'Conv_AvenirLTStd Black';
                  display: inline-block;
                  color: #fff;
                  padding: 7px 15px 5px 15px;
                  text-decoration: none;
                  text-transform: none;
                  margin-right: 5px;
                  position: absolute;
                  top: -33px;
                  font-weight: normal;
                  &:hover{
                    opacity: 1;
                  }
                }
                .artikel{
                  background: #008942;
                }
                .trick{
                  background: #d42707;
                }
                .quick{
                  background: #2a9aff;
                }
                .fun{
                  background: #ff890d;
                }
              }
              .source{
                position: absolute;
                bottom: 10px;
                font-size: 11px;
                letter-spacing: 1px;
                font-family: 'Conv_Avenir Heavy Oblique';
              }
            }
          }
        }
      }
    }
  }
  .section-home-five{
    padding: 80px 0 0;
    @media screen and (max-width: 768px) {
      padding: 0;
    }
    .komunitas-pohon-wrapper{
      background: url(../images/home-parallax.jpg) no-repeat center;
      height: 50vw;
      background-size: cover;
      position: relative;
      @media screen and (max-height: 415px) {
        height: 100vh;
      }
      @media screen and (min-width: 380px)and(max-width: 416px) {
        height: 65vh;
      }
      @media screen and (min-width: 320px)and(max-width: 376px) {
        height: 80vh;
      }
      @media screen and (min-width: 1920px) {
        height: 44vw;
      }
      .content-komunitas-pohon{
        position: relative;
        z-index: 1;
        .title-wrapper{
          text-align: center;
          .title{
            text-transform: uppercase;
            color: #fff;
            font-size: 30px;
            line-height: 60px;
            padding-top: 10px;
            padding-left: 20px;
            display: inline-block;
            font-family: "Conv_AvenirLTStd Black";
            margin-bottom: 5%;
            border-left: 3px solid rgb(0, 137, 66);
          }
        }
        .container{
          width: 750px;
          @media screen and (max-width: 1024px) {
            width: 100%;
          }
          .content-wrapper{
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: left;
            height: 30vw;
            @media screen and (max-width: 991px) {
              height: auto;
            }
            .desc{
              font-size: 19px;
              color: #fff;
              width: 50%;
              @media screen and (max-width: 768px) {
                width: 100%;
              }
            }
            strong{
              display: block;
              font-size: 20px;
              color: #fff;
              margin: 20px 0;
            }
            .readmore{
              text-align: center;
              a{
                -webkit-border-top-left-radius: 100px;
                -webkit-border-bottom-right-radius: 100px;
                -moz-border-radius-topleft: 100px;
                -moz-border-radius-bottomright: 100px;
                border-top-left-radius: 100px;
                border-bottom-right-radius: 100px;
                border: 2px solid #fff;
                width: 275px;
                font-size: 14px;
                color: #fff;
                text-transform: uppercase;
                height: 50px;
                line-height: 45px;
                letter-spacing: 2px;
                display: inline-block;
                text-decoration: none;
                &:hover{
                  background: #fff;
                  color: #000;
                }
              }
            }
          }
        }
      }
      .shadow{
        position: absolute;
        background: rgba(0,0,0,0.5);
        width: 100%;
        height: 100%;
        top: 0;
      }
    }
  }

}

.select2-dropdown{
  border: none;
  .select2-results__options{
    background: #7fddad;
    li{
      color: #fff;
      font-size: 18px;
    }
  }
}

#splashscreen{
  .modal-wrapper{
    width:100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    .modal-dialog{
      width:850px;
      margin:0 auto;
      @media(max-width: $screen-sm){ 
        width: 90%;  
      } 
      .modal-content{
        width:100%;
        margin: 0 auto;
        background-color: transparent;        
        .modal-body{
          padding: 0px;   
          .close{
            padding: 5px 10px;
            position: absolute;
            right: -10px;
            top:0;
            cursor: pointer;
            color:#fff;
            background: #4a4a4a;
            opacity:1;
            border: 0;
            z-index: 9999;
            text-shadow:none;
            font-size: 20px;
            @media(max-width: $screen-sm){        
              background: #ec1c24;
              padding: 7px 8px;
            }
            span{
              font-size: 17px;
              letter-spacing: -0.5px;
              color: #969696;
              font-weight: 400;
              margin-right:10px;
            }
          }     
          .showcase-wrapper{
            background: #ec1c24;
            @media(max-width: $screen-sm){
              width:90%;
              margin: 0 5%;
              margin-top: 7%;
            }
            img{
              width:100%;
              margin:0 auto;
              text-align:center;
            }
          }    
        }
      }
    }
  }  
}